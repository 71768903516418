import { Link } from "react-router-dom"
import Logo from "../image/logoitem.png"
import OpenMenu from "../image/open-menu.svg"
import { LeftMenu } from "./LeftMenu"
import { useState } from "react"
export const Header = () => {
    const [openMenu, setOpenMenu] = useState(false)
    
    return (
        <>
            <header>
                <div className="row">
                    <div className="col-md-4 logo">
                        <Link to={"/"}>
                            <img src={Logo} />
                        </Link>
                    </div>
                    <div className="col-md-8">
                        <div className="header-setting">
                            <Link to={'/dashboard/setting/'}>
                                <span>Профиль</span>
                                <svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="12" cy="6" r="4" stroke="#1C274C" stroke-width="1.5"/>
                                    <path d="M15 20.6151C14.0907 20.8619 13.0736 21 12 21C8.13401 21 5 19.2091 5 17C5 14.7909 8.13401 13 12 13C15.866 13 19 14.7909 19 17C19 17.3453 18.9234 17.6804 18.7795 18" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"/>
                                </svg>
                            </Link>

                            <div>
                                <span>Выйти</span>
                                <svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9 4.5H8C5.64298 4.5 4.46447 4.5 3.73223 5.23223C3 5.96447 3 7.14298 3 9.5V10M9 19.5H8C5.64298 19.5 4.46447 19.5 3.73223 18.7678C3 18.0355 3 16.857 3 14.5V14" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"/>
                                    <path d="M13.6576 2.34736C11.4955 1.97026 10.4145 1.78171 9.70725 2.4087C9 3.03569 9 4.18259 9 6.4764V17.5236C9 19.8174 9 20.9643 9.70725 21.5913C10.4145 22.2183 11.4955 22.0297 13.6576 21.6526L15.9864 21.2465C18.3809 20.8288 19.5781 20.62 20.2891 19.7417C21 18.8635 21 17.5933 21 15.0529V8.94711C21 6.40672 21 5.13652 20.2891 4.25826C19.814 3.67133 19.1217 3.38338 18 3.13228" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"/>
                                    <path d="M12 11V13" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <img className={`menu-opener`} onClick={()=>{
                setOpenMenu(!openMenu)
            }} src={OpenMenu} />
            <div className={`mob-menu ${openMenu ? "active" : ""}`}>
                <LeftMenu />
            </div>
        </>
    )
}