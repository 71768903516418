import { Route, Routes } from "react-router-dom";
import { Layout } from "./layouts/layout" 
import { useMyRoutes } from "./routes";
import { Header } from "./component/Header";
import { LeftMenu } from "./component/LeftMenu";
import { Footer } from "./component/Footer";
import { GlobalContext } from "./context/GlobalContext";
import { User } from "./classes";
import { useContext } from "react";

function App() {
    const routes = useMyRoutes() 
    const auth = useContext(GlobalContext) 
    
    return (
        <GlobalContext.Provider value={{
            user: new User
        }}> 
            <Routes>
                <Route element={<Layout />}>{routes}</Route>
            </Routes>  
        </GlobalContext.Provider>
    );
}

export default App;
