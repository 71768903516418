import { ChangeEvent, useContext, useEffect, useState } from "react"
import { Link, useParams, useSearchParams } from "react-router-dom"
import Logo from "../../image/logoitem.png"
import { GlobalContext } from "../../context/GlobalContext"

export const LoginPage = () => {
    const auth = useContext(GlobalContext)   
    const [load, setLoad] = useState(true)  
    const [popupType, setPopupType] = useState("") 
    const [popupError, setPopupError] = useState(true) 
    const [form, setForm] = useState({ 
        email: "",
        pass: ""
    })

    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, [event.target.name]: event.target.value })
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => { 
        event.preventDefault()
        if(load){
            setLoad(false)
            auth.user.login(form.email, form.pass).then(function(response){
                if(!response.error)
                    window.location.replace("/")
                setPopupType(response.msg)
                setPopupError(response.error)
            }).catch(function(error){
                alert("error")
            })
        }
    }

    return(
        <>
            <div className="auth-form">
                <img src={Logo} />
                <h2>Авторизация</h2>
                <form onSubmit={handleSubmit}>
                    <label>
                        Email
                        <input type="email" placeholder="E-mail" required name="email" value={form.email} onChange={changeHandler} />
                    </label>
                    <label>
                        Пароль
                        <input type="password" placeholder="Пароль" required name="pass" value={form.pass} onChange={changeHandler} /> 
                    </label>
                    <label>
                        <button>Войти</button>
                    </label>
                </form>
            </div>
        
            { 
                popupType != "" ? (                                 
                    <div className="popup-msg">
                        <div className="popup-msg__body">
                            <h3>{popupType}</h3>
                                <div className="popup-msg__link" onClick={()=>{
                                    setLoad(true)
                                    setPopupType("") 
                                }}>
                                    Продолжить
                                </div>
                        </div>
                    </div>
                ):""
            }     
        </>
    )
}