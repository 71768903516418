import { Link } from "react-router-dom"
import MenuUser from "../image/menu_users.svg"
import MenuProtect from "../image/menu_protect.svg"
import MenuPay from "../image/menu_pay.svg"
import MenuSetting from "../image/menu_setting.svg"
import MenuFaq from "../image/menu-faq.svg"
export const LeftMenu = () => {
    return (
        <div className="left-menu">
            <div>
                <ul className="left-menu__fline">
                    <li>
                        <Link to={"/dashboard/user/"} style={{backgroundImage: `url('${MenuUser}')`}}>Пользователи</Link>
                    </li>
                    <li>
                        <Link to={"/dashboard/insurances/"} style={{backgroundImage: `url('${MenuProtect}')`}}>Страховки</Link>
                    </li>
                    <li>
                        <Link to={"/dashboard/donation/"} style={{backgroundImage: `url('${MenuPay}')`}}>Членские взносы</Link>
                    </li>
                    <li>
                        <Link to={"/dashboard/setting/"} style={{backgroundImage: `url('${MenuSetting}')`}}>Настройки</Link>
                    </li>
                    <li>
                        <Link to={"/developers/"} style={{backgroundImage: `url('${MenuFaq}')`}}>Документация</Link>
                    </li>
                </ul>
            </div>
        </div>
    )
}