import { ChangeEvent, useEffect, useState } from "react"
import { Link, useParams, useSearchParams } from "react-router-dom"


export const DashboardPage = () => {
    return(
        <div className="row">
            <div className="col-md-8">
                <div className="lk-block">
                    <h2 className="title">История операций</h2>
                    <div className="lk-block__body" style={{padding: 0}}>
                        <div className="table-wrap">
                            <table>
                                <thead>
                                    <tr>
                                        <th>id операции</th>
                                        <th>Тип операции</th>
                                        <th>Время операции</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td data-label="id операции">112</td>
                                        <td data-label="Тип операции">Создание пользователя</td>
                                        <td data-label="Время операции">12.12.2003 12:12</td>
                                    </tr>
                                    <tr>
                                        <td data-label="id операции">112</td>
                                        <td data-label="Тип операции">Создание пользователя</td>
                                        <td data-label="Время операции">12.12.2003 12:12</td>
                                    </tr>
                                    <tr>
                                        <td data-label="id операции">112</td>
                                        <td data-label="Тип операции">Создание пользователя</td>
                                        <td data-label="Время операции">12.12.2003 12:12</td>
                                    </tr>
                                    <tr>
                                        <td data-label="id операции">112</td>
                                        <td data-label="Тип операции">Создание пользователя</td>
                                        <td data-label="Время операции">12.12.2003 12:12</td>
                                    </tr>
                                    <tr>
                                        <td data-label="id операции">112</td>
                                        <td data-label="Тип операции">Создание пользователя</td>
                                        <td data-label="Время операции">12.12.2003 12:12</td>
                                    </tr>
                                    <tr>
                                        <td data-label="id операции">112</td>
                                        <td data-label="Тип операции">Создание пользователя</td>
                                        <td data-label="Время операции">12.12.2003 12:12</td>
                                    </tr>
                                    <tr>
                                        <td data-label="id операции">112</td>
                                        <td data-label="Тип операции">Создание пользователя</td>
                                        <td data-label="Время операции">12.12.2003 12:12</td>
                                    </tr>
                                    <tr>
                                        <td data-label="id операции">112</td>
                                        <td data-label="Тип операции">Создание пользователя</td>
                                        <td data-label="Время операции">12.12.2003 12:12</td>
                                    </tr>
                                    <tr>
                                        <td data-label="id операции">112</td>
                                        <td data-label="Тип операции">Создание пользователя</td>
                                        <td data-label="Время операции">12.12.2003 12:12</td>
                                    </tr>
                                    <tr>
                                        <td data-label="id операции">112</td>
                                        <td data-label="Тип операции">Создание пользователя</td>
                                        <td data-label="Время операции">12.12.2003 12:12</td>
                                    </tr>
                                    <tr>
                                        <td data-label="id операции">112</td>
                                        <td data-label="Тип операции">Создание пользователя</td>
                                        <td data-label="Время операции">12.12.2003 12:12</td>
                                    </tr>
                                    <tr>
                                        <td data-label="id операции">112</td>
                                        <td data-label="Тип операции">Создание пользователя</td>
                                        <td data-label="Время операции">12.12.2003 12:12</td>
                                    </tr>
                                    <tr>
                                        <td data-label="id операции">112</td>
                                        <td data-label="Тип операции">Создание пользователя</td>
                                        <td data-label="Время операции">12.12.2003 12:12</td>
                                    </tr>
                                    <tr>
                                        <td data-label="id операции">112</td>
                                        <td data-label="Тип операции">Создание пользователя</td>
                                        <td data-label="Время операции">12.12.2003 12:12</td>
                                    </tr>
                                    <tr>
                                        <td data-label="id операции">112</td>
                                        <td data-label="Тип операции">Создание пользователя</td>
                                        <td data-label="Время операции">12.12.2003 12:12</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="lk-block">
                    <h2 className="title">Статистика</h2>
                    <div className="lk-block__body setting">
                        <p>
                            Кол-во пользователей: <span>222</span>
                        </p>
                        <p>
                            Кол-во активных страховок: <span>222</span>
                        </p>
                        <p>
                            Кол-во членских взносов: <span>222</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}