import { ChangeEvent, useEffect, useState } from "react"
import { Link, useParams, useSearchParams } from "react-router-dom"
import Logo from "../../image/logoitem.png"

export const UserItemPage = () => {
    return(
        <div className="row">
            <div className="col-12">
                <h1 className="page-title">Кто-то что-то #1212</h1>
            </div>
            <div className="col-12">
                <div className="lk-block">
                    <div className="item-tabs">
                        <div className="active">Подробности</div>
                        <div className="">Еще таб</div>
                        <div className="">Еще таб</div>
                        <div className="">Еще таб</div>
                    </div>
                    <div className="item-tab__content">
                        <h2 className="title">Подробности</h2>
                        <div className="item-tab__wrapper">
                            <div className="item-tab__props-list">
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="item-tab__title">
                                            <div className="grid">
                                                <span>Название свойства:</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="item-tab__value">
                                            111221
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="item-tab__title">
                                            <div className="grid">
                                                <span>Название свойства:</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="item-tab__value">
                                            <input type="text" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="item-tab__title">
                                            <div className="grid">
                                                <span>Название свойства:</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="item-tab__value">
                                            <select>
                                                <option>00000</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item-tab__btns">
                                <button className="save" style={{
                                    backgroundColor: "#93c123"
                                }}>Сохранить</button>

                                <button className="save" style={{
                                    backgroundColor: "#c91924"
                                }}>Удалить</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}