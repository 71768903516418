import axios from 'axios'
import { error } from 'console'

export const host = "https://api.enjoinsport.ru/"

export class User {
    isAuthenticated: boolean = false
    userToken: string = ""
    title: string = ""

    constructor () {
        if(localStorage.getItem("userStorageEnjoing")){
            const userStorage = JSON.parse(localStorage.getItem("userStorageEnjoing") || "")
            this.isAuthenticated = true
            this.userToken = userStorage.userToken
            this.title = userStorage.title
        }
    }

    logout(){
        localStorage.removeItem("userStorageEnjoing")
        this.isAuthenticated = false
        this.userToken = "" 
        this.title = ""
    } 


    async login(email: string, pass: string){
        let data = {
            "error": false,
            "msg": ""
        }

        if(!/((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20})/.test(pass)){
            return {
                "error": true,
                "msg": "Ваш пароль должен содержать не менее восьми (8) символов в том числе: по крайней мере один (1) буквы в верхнем регистре"
            }
        }

        await axios.post(`${host}login/`, {
            email: email,
            password: pass,
        }).then(res => { 
            if(res.data.error == false){
                localStorage.setItem("userStorageEnjoing", JSON.stringify({ 
                    isAuthenticated: true,
                    userToken: res.data.userToken,
                    title: res.data.title,
                }))
                data = {
                    "error": false,
                    "msg": "Вы успешно вошли"
                }
            }else{
                data = {
                    "error": true,
                    "msg": res.data.msg
                }        
            }
        }).catch(function (error) { 
            console.log(error)
            data = {
                "error": true,
                "msg": "error"
            }
        }) 
        return data
    }
}
