import { payLimits, paySports } from "../info"

export const LimitsPage = () => {
    return (
        <div>
            <ul>
                {
                    payLimits.map((item, index) => {
                        return(
                            <li>
                                <b>{item.val}</b> - {item.title} руб.
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}