import React from "react"
import { info } from "./info"

import { JsonView, allExpanded, darkStyles, defaultStyles } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';

export const DeveloperPage = () => {

    return(
        <div className="developer">
            <div className="developer-sidebar">
                <ul>
                    {
                        info.map((item, index) => {
                            return(
                                <li key={index}>
                                    <span>
                                        {item.title}
                                        {
                                            item.children.length ? (
                                                <svg width="13px" height="13px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.70711 9.71069C5.31658 10.1012 5.31658 10.7344 5.70711 11.1249L10.5993 16.0123C11.3805 16.7927 12.6463 16.7924 13.4271 16.0117L18.3174 11.1213C18.708 10.7308 18.708 10.0976 18.3174 9.70708C17.9269 9.31655 17.2937 9.31655 16.9032 9.70708L12.7176 13.8927C12.3271 14.2833 11.6939 14.2832 11.3034 13.8927L7.12132 9.71069C6.7308 9.32016 6.09763 9.32016 5.70711 9.71069Z" fill="#0F0F0F"/>
                                                </svg>
                                            ):""
                                        }
                                    </span>
                                    {
                                        item.children.length ? (
                                            <ul>
                                                {  
                                                    item.children.map((item2, index2) => {
                                                        return(
                                                            <li key={index2}>{item2.title}</li> 
                                                        )
                                                    }) 
                                                }
                                            </ul>
                                        ):""
                                    }
                                </li>

                            )
                        })
                    }
                </ul>
            </div>

            <div className="developer-space">
                <h2>Быстрый старт</h2>
                <p>
                    API EnJoinSport позволяет обеспечить удобную и быструю работу с вашими пользователями в вебе и на мобильных устройствах.
                    Этот раздел поможет вам настроить передачу данных в ваши web-ресурсы.
                </p>
                <div className="errorkey">
                    <p>
                        <b>Коды ошибок</b>
                    </p>
                    <ul>
                        <li>
                            <b>0</b> - ошибка отсутствует
                        </li>
                        <li>
                            <b>1</b> - ошибка входных данных
                        </li>
                        <li>
                            <b>2</b> - пользователь с данным userID не найден
                        </li>
                        <li>
                            <b>3</b> - страховка с данным mid не находится в статусе ожидания оплаты
                        </li>
                        <li>
                            <b>4</b> - ошибка формирования запроса
                        </li>
                        <li>
                            <b>5</b> - открытая страховка с данным mid не найдена или уже закрыта
                        </li>
                        <li>
                            <b>6</b> - неопознанная ошибка от страховой службы
                        </li>
                        <li>
                            <b>7</b> - страховка с данным mid не найдена
                        </li>
                        <li>
                            <b>8</b> - дата рождения пользователя не указана
                        </li>
                        <li>
                            <b>9</b> - не все поля пользователя заполнены
                        </li>
                        <li>
                            <b>10</b> - не все поля родителя заполнены
                        </li>
                        <li>
                            <b>11</b> - у данного пользователя уже есть страховка ожидающая оплаты
                        </li>
                        <li>
                            <b>12</b> - партнер уже существует
                        </li>
                        <li>
                            <b>13</b> - платеж с данным orderID не существует
                        </li>
                        <li>
                            <b>14</b> - логин или пароль указан неверно
                        </li>
                        <li>
                            <b>15</b> - аккаунт временно заблокирован
                        </li>
                        <li>
                            <b>16</b> - платеж с данным orderID уже существует
                        </li>
                        <li>
                            <b>17</b> - пользователь с данным userID уже существует
                        </li>
                    </ul>
                </div>
                {
                    info.map((item, index) =>{
                        return (
                            <div className="developer-block">
                                <h3>{item.title}</h3>
                                {item.desc}

                                {
                                    item.code ? (
                                        <div className="codeItem">
                                            <h5>Пример тела запроса</h5>
                                            <React.Fragment>
                                                <JsonView data={item.code} shouldExpandNode={allExpanded} style={darkStyles} />
                                            </React.Fragment>
                                        </div>
                                    ):""                  
                                }
                                {
                                    item.response ? (
                                        <div className="codeItem">
                                            <h5>Пример тела ответа</h5>
                                            <React.Fragment>
                                                <JsonView data={item.response} shouldExpandNode={allExpanded} style={darkStyles} />
                                            </React.Fragment>
                                        </div>
                                    ):""
                                }
                                {
                                    item.children.map((item, index) => {
                                        return(
                                            <div className="developer-block__child">
                                                <h4>{item.title}</h4>
                                                {item.desc}
                                                {
                                                    item.code ? (
                                                        <div className="codeItem">
                                                            <h5>Пример тела запроса</h5>
                                                            <React.Fragment>
                                                                <JsonView data={item.code} shouldExpandNode={allExpanded} style={darkStyles} />
                                                            </React.Fragment>
                                                        </div>
                                                    ):""                  
                                                }
                                                {
                                                    item.response ? (
                                                        <div className="codeItem">
                                                            <h5>Пример тела ответа</h5>
                                                            <React.Fragment>
                                                                <JsonView data={item.response} shouldExpandNode={allExpanded} style={darkStyles} />
                                                            </React.Fragment>
                                                        </div>
                                                    ):""
                                                }
                                           
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}