import { payPeriods, paySports } from "../info"

export const PeriodsPage = () => {
    return (
        <div>
            <ul>
                {
                    payPeriods.map((item, index) => {
                        return(
                            <li>
                                <b>{item.val}</b> - {item.title}
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}