import { paySports } from "../info"


export const SportsPage = () => {
    return (
        <div>
            <ul>
                {
                    paySports.map((item, index) => {
                        return(
                            <li>
                                <b>{item.id}</b> - {item.title}
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}