import { ChangeEvent, useEffect, useState } from "react"
import { Link, useParams, useSearchParams } from "react-router-dom"
import Logo from "../../image/logoitem.png"

export const UserPage = () => {
    return(
        <div className="row">
            <div className="col-12">
                <h1 className="page-title">Пользователи</h1>
            </div>
            <div className="col-md-9">
                <div className="lk-block search">
                    <input type="text" placeholder="Поиск..." />
                    <button>
                        <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.7955 15.8111L21 21M18 10.5C18 14.6421 14.6421 18 10.5 18C6.35786 18 3 14.6421 3 10.5C3 6.35786 6.35786 3 10.5 3C14.6421 3 18 6.35786 18 10.5Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </button>
                </div>
            </div>
            <div className="col-md-3">
                <Link to={'/user/add/'} className="add-btn">
                    Добавить элемент
                </Link>
            </div>
            <div className="col-md-12">
                <div className="lk-block">
                    <div className="table-wrap">
                        <table>
                            <thead>
                                <tr>
                                    <th>id</th>
                                    <th>id пользователя</th>
                                    <th>Время создания</th>
                                    <th>Имя</th>
                                    <th>Фамилия</th>
                                    <th>E-mail</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td data-label="id">
                                        <Link to={`/user/${1}/`}>112</Link>
                                    </td> 
                                    <td data-label="id пользователя">1212</td>
                                    <td data-label="Время создания">12.12.2003</td>
                                    <td data-label="Имя">Имя</td>
                                    <td data-label="Фамилия">Фамилия</td>
                                    <td data-label="E-mail">test@gmail.com</td>
                                </tr> 
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}