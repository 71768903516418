import React, { ChangeEvent, useContext, useState, Component } from "react"
import {Route} from 'react-router-dom'  
import { DashboardPage } from "./pages/DashboardPage"
import { LoginPage } from "./pages/LoginPage"
import { InsurancePage } from "./pages/InsurancePage"
import { DonationPage } from "./pages/DonationPage"
import { UserPage } from "./pages/UserPage"
import { UserItemPage } from "./pages/UserPage/UserItemPage"
import { InsuranceItemPage } from "./pages/InsurancePage/InsuranceItemPage"
import { DonationItemPage } from "./pages/DonationPage/DonationItemPage"
import { DonationAddPage } from "./pages/DonationPage/DonationAddPage"
import { InsuranceAddPage } from "./pages/InsurancePage/InsuranceAddPage"
import { UserAddPage } from "./pages/UserPage/UserAddPage"
import { SettingPage } from "./pages/SettingPage"
import { GlobalContext } from "./context/GlobalContext"
import { DeveloperPage } from "./pages/DeveloperPage"
import { SportsPage } from "./pages/DeveloperPage/SportsPage"
import { PeriodsPage } from "./pages/DeveloperPage/PeriodsPage"
import { LimitsPage } from "./pages/DeveloperPage/LimitsPage"
import { LandingPage } from "./pages/LandingPage"

export const useMyRoutes = () => { 
    const auth = useContext(GlobalContext) 
    const frontList = [
        '/',
        '/login/',
        '/developers/'
    ]

    return (
        <>  
            <Route path={`/`} element={<LandingPage/>} />  
            <Route path={`/login/`} element={<LoginPage/>} />  
            <Route path={`/developers/`} element={<DeveloperPage/>} /> 
                <Route path={`/developers/sports/`} element={<SportsPage/>} /> 
                <Route path={`/developers/periods/`} element={<PeriodsPage/>} /> 
                <Route path={`/developers/limits/`} element={<LimitsPage/>} /> 
                
            {
                auth.user.isAuthenticated ? (
                    <>
                        <Route path={`/dashboard/`} element={<DashboardPage/>} />    
                        <Route path={`/dashboard/insurances/`} element={<InsurancePage/>} />  
                            <Route path={`/dashboard/insurances/:id/`} element={<InsuranceItemPage/>} />     
                            <Route path={`/dashboard/insurances/add/`} element={<InsuranceAddPage/>} />      
                        <Route path={`/dashboard/donation/`} element={<DonationPage/>} />  
                            <Route path={`/dashboard/donation/:id/`} element={<DonationItemPage/>} />  
                            <Route path={`/dashboard/donation/add/`} element={<DonationAddPage/>} />  
                        <Route path={`/dashboard/user/`} element={<UserPage/>} />   
                            <Route path={`/dashboard/user/:id/`} element={<UserItemPage/>} />  
                            <Route path={`/dashboard/user/add/`} element={<UserAddPage/>} />  
                        <Route path={`/dashboard/setting/`} element={<SettingPage/>} />   
                    </>
                ):""
            }  
            
        </>
    )
}