import React, { ChangeEvent, useState } from "react";
import Slider from "react-slick";
import axios, {isCancel, AxiosError} from 'axios';
import slide01 from "../../image/slide_01.png"
import slide02 from "../../image/slide_02.png"
import slide03 from "../../image/slide_03.png"
import { Link } from "react-router-dom";

export const LandingPage = () => {
    const [openForm, setOpenForm] = useState(false)
    const [testForm, setTestForm] = useState(true)
    const [sendForm, setSendForm] = useState(false)
    const [form, setForm] = useState({
        fio: "",
        email: "",
        phone: ""
    })

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000
    }


    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, [event.target.name]: event.target.value })
    }
    
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => { 
        event.preventDefault()
        if(testForm){
            setTestForm(true)
            axios.post('https://api.enjoinsport.ru/send-msg/',form).then(function(response){
                console.log(response)
                setSendForm(true)
                setForm({
                    fio: "",
                    email: "",
                    phone: ""
                })
            }).catch(function(error){
                alert("error")
            })
            setTestForm(false)
        }
        
    }

    return (
        <>
            <section className="banner">
                <div className="content">
                    <div className="row">
                        <div className="order-2 order-lg-1 col-lg-6">
                            <Link to={'/developers/'} className="newmarker">
                                <span>Обновление</span> Страхование спортсменов
                            </Link>
                            <h2>Эффективные функциональные решения для цифрового развития спортивной организации</h2>
                            <p>
                                <b>EnJoinSport</b> - это открытая цифровая платформа для развития спортивных организаций.<br/>
                                Мы помогаем федерациям, спортивным клубам, сборным командам, школам, судейским коллегиям и другим участникам спортивного процесса развиваться как на региональном, так и на федеральном уровне в части цифровизации взаимодействия с целевой аудиторией.<br/>
                                Наша открытая цифровая платформа позволяет перевести взаимодействие в онлайн используя унифицированные решения для роста аудитории и развития вашего спорта. А ключевой приоритет для нас - это конечный пользователь.
                            </p>
                            <button className="bannerbtn" onClick={()=>{setOpenForm(true)}}>Оставить заявку</button>
                        </div>
                        <div className="order-1 order-lg-2 col-lg-6">
                            <div  className="slider-block" style={{}}>
                                <div className="slider-wrapper">
                                    <Slider {...settings}>
                                        <div>
                                            <img src={slide01} />
                                        </div>
                                        <div>
                                            <img src={slide02} />
                                        </div>
                                        <div>
                                            <img src={slide03} />
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {
                openForm ? (
                    <div className="popup-form">
                        <div>
                            <div className="popup-close">
                                <svg onClick={()=>{
                                    setOpenForm(false)
                                }} width="30px" height="30px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                                    <path fill="#000000" d="M195.2 195.2a64 64 0 0 1 90.496 0L512 421.504 738.304 195.2a64 64 0 0 1 90.496 90.496L602.496 512 828.8 738.304a64 64 0 0 1-90.496 90.496L512 602.496 285.696 828.8a64 64 0 0 1-90.496-90.496L421.504 512 195.2 285.696a64 64 0 0 1 0-90.496z"/>
                                </svg>
                            </div>
                            {
                                sendForm ? (
                                    <h3 style={{textAlign: "center"}}>Ваш запрос успешно отправлен</h3>
                                ):(
                                    <form onSubmit={handleSubmit}>
                                        <label>
                                            ФИО*
                                            <input type="text" name="fio" value={form.fio} onChange={changeHandler} required />
                                        </label>
                                        <label>
                                            Email*
                                            <input type="email" name="email" value={form.email} onChange={changeHandler} required />
                                        </label>
                                        <label>
                                            Номер телефона
                                            <input type="text" name="phone" value={form.phone} onChange={changeHandler} required />
                                        </label>
                                        <button>Отправить</button>
                                    </form>
                                )
                            }
                        </div>
                    </div>
                ):""
            }

        </>


    )
}