import { Link } from "react-router-dom";
import insurance from "../../image/insurance.png"
export const info = [ 
    {
        "title": "Авторизация",
        "desc": <>
            <p>
                Перед выполнением любого запроса Вам необходимо получить token. Для этого необходимо выполнить login запрос.
            </p>
            <p>
                В случае неуспеха, метод вернет error=true и описание ошибки
            </p>
            <ul>
                <li>
                    <b>URL: </b>https://api.enjoinsport.ru/login/
                </li>
                <li>
                    <b>Метод: </b>POST
                </li>
                <li className="developer-block__props">
                    <b>Свойства:</b>
                    <ul>
                        <li>
                            <b>email</b> <b className="maxlength">[max length: 255]</b> - email <span>обязательное поле</span>
                        </li>
                        <li>
                            <b>password</b> <b className="maxlength">[max length: 255]</b> - пароль (Выдается администратором) <span>обязательное поле</span>
                        </li>
                    </ul>
                </li>
            </ul> 
        </>,
        "code": {
            "email": "info@enjoinsport.ru", 
            "password": "123456789Qw"
        },
        "response": {
            "error": false,
            "userToken": "token",
            "title": "Федерация"
        },
        "children": []
    },
    {
        "title": "ID спортсмена",
        "desc": <>
            <p>В процессе работы с пользователями можно реализовать следующий набор действий, а именно:</p>
            <ul>
                <li>Создание пользователя</li>
                <li>Обновление пользователя</li>
                <li>Вывод списка пользователей</li>
                <li>Поиск пользователя по id, ФИО или email</li>
                <li>Удаление пользователя</li>
            </ul>
        </>,
        "children": [
            {
                "title": "Создание пользователя",
                "desc": <>
                    <p>
                        Для выполнения запроса в header необходимо передать ключ выданный администратором в момент заключения договора <b>(Authorization: Token Ваш_токен)</b>.
                    </p>
                    <p>
                        В случае неуспеха, метод вернет error=true и описание ошибки
                    </p>
                    <ul>
                        <li>
                            <b>URL: </b>https://api.enjoinsport.ru/user/
                        </li>
                        <li>
                            <b>Метод: </b>POST
                        </li>
                        <li className="developer-block__props">
                            <b>Свойства:</b>
                            <ul>
                                <li>
                                    <b>userID</b> - уникальный идентификатор пользователя. (Укажите 0 для автоматической генерации ID в порядке возрастания) <span>обязательное поле</span>
                                </li>
                                <li>
                                    <b>fnameRU</b> <b className="maxlength">[max length: 255]</b> - имя (RU) <span>обязательное поле</span>
                                </li>
                                <li>
                                    <b>lnameRU</b> <b className="maxlength">[max length: 255]</b> - фамилия (RU) <span>обязательное поле</span>
                                </li>
                                <li>
                                    <b>mnameRU</b> <b className="maxlength">[max length: 255]</b> - отчество (RU) <span>обязательное поле</span>
                                </li>
                                <li>
                                    <b>fnameENG</b> <b className="maxlength">[max length: 255]</b> - имя (ENG)
                                </li>
                                <li>
                                    <b>lnameENG</b> <b className="maxlength">[max length: 255]</b> - фамилия (ENG)
                                </li>
                                <li>
                                    <b>mnameENG</b> <b className="maxlength">[max length: 255]</b> - отчество (ENG)
                                </li>
                                <li>
                                    <b>email</b> <b className="maxlength">[max length: 255]</b> - email <span>обязательное поле</span>
                                </li>
                                <li>
                                    <b>bday</b> - дата рождения <span>обязательное поле</span>
                                </li>
                                <li>
                                    <b>phone</b> <b className="maxlength">[max length: 20]</b> - номер телефона <span>обязательное поле</span>
                                </li>
                                <li>
                                    <b>passportID</b> <b className="maxlength">[max length: 20]</b> - номер паспорта
                                </li>
                                <li>
                                    <b>passportSeries</b> <b className="maxlength">[max length: 10]</b> - серия паспорта
                                </li>
                                <li>
                                    <b>regAddress</b> - адрес регистрации
                                </li>
                                <li>
                                    <b>passportIssued</b> - дата выдачи паспорта
                                </li>
                                <li>
                                    <b>departCode</b> <b className="maxlength">[max length: 10]</b> - код подразделения
                                </li>
                                <li>
                                    <b>whoIssued</b> - кем выдан паспорта
                                </li>
                                <li>
                                    <b>oms</b> <b className="maxlength">[max length: 255]</b> - полис ОМС №
                                </li>
                                <li>
                                    <b>snils</b> <b className="maxlength">[max length: 255]</b> - СНИЛС №
                                </li>
                                <li>
                                    <b>pfdo</b> <b className="maxlength">[max length: 255]</b> - ПФДО №
                                </li>
                                <li>
                                    <b>citizenship</b> <b className="maxlength">[max length: 255]</b> - гражданство
                                </li>
                                <li>
                                    <b>federalDist</b> <b className="maxlength">[max length: 255]</b> - федеральный округ
                                </li>
                                <li>
                                    <b>subject</b> <b className="maxlength">[max length: 255]</b> - субъект
                                </li>
                                <li>
                                    <b>city</b> <b className="maxlength">[max length: 255]</b> - город
                                </li>
                                <li>
                                    <b>resAddress</b> - адрес проживания
                                </li>
                                <li>
                                    <b>familyStatus</b> <b className="maxlength">[max length: 255]</b> - социальный статус семьи
                                </li>
                                <li>
                                    <b>parentBday</b> - дата рождения родителя
                                </li>
                                <li>
                                    <b>fnameParent</b> <b className="maxlength">[max length: 255]</b> - Фамилия родителя
                                </li>
                                <li>
                                    <b>lnameParent</b> <b className="maxlength">[max length: 255]</b> - Имя родителя
                                </li>
                                <li>
                                    <b>mnameParent</b> <b className="maxlength">[max length: 255]</b> - Отчество родителя
                                </li>
                                <li>
                                    <b>genderParent</b> <b className="maxlength">[max length: 1]</b> - Пол родителя (М/Ж)
                                </li>
                                <li>
                                    <b>props</b> - массив дополнительных свойств пользователя (json объект форматированный в строку)
                                </li>
                            </ul>
                        </li>
                    </ul>
                </>,
                "code": {
                    "userID": 1,
                    "fnameRU": "имя (RU)",
                    "lnameRU": "фамилия (RU)",
                    "mnameRU": "отчество (RU)",
                    "fnameENG": "имя (ENG)",
                    "lnameENG": "фамилия (ENG)",
                    "mnameENG": "отчество (ENG)",
                    "email": "info@enjoinsport.com",
                    "bday": "2024-01-30",
                    "phone": "79030000000",
                    "passportID": "000000",
                    "passportSeries": "0000",
                    "regAddress": "адрес регистрации",
                    "passportIssued": "2024-01-30",
                    "departCode": "код",
                    "whoIssued": "кем выдан паспорта",
                    "oms": "0000000000000000",
                    "snils": "000-000-000-00",
                    "pfdo": "ПФДО №",
                    "citizenship": "гражданство",
                    "federalDist": "федеральный округ",
                    "subject": "субъект",
                    "city": "город",
                    "resAddress": "адрес проживания",
                    "familyStatus": "социальный статус семьи",
                    "parentBday": "2024-01-30",
                    "fnameParent": "фамилия родителя",
                    "lnameParent": "имя родителя",
                    "mnameParent": "отчество родителя",
                    "genderParent": "М",
                    "props": "{\\\"props\\\": \\\"value\\\"}"
                },
                "response": {
                    "error": false,
                    "msg": "Пользователь создан",
                    "userID": 48
                }
            },
            {
                "title": "Обновление пользователя",
                "desc": <>
                    <p>
                        Для выполнения запроса в header необходимо передать ключ выданный администратором в момент заключения договора <b>(Authorization: Token Ваш_токен)</b>.
                    </p>
                    <p>
                        В случае неуспеха, метод вернет error=true и описание ошибки
                    </p>
                    <ul>
                        <li>
                            <b>URL: </b>https://api.enjoinsport.ru/user/<span className="url-props">[userID]</span>/
                        </li>
                        <li>
                            <b>Метод: </b>PUT
                        </li>
                        <li className="developer-block__props">
                            <b>Свойства:</b>
                            <ul> 
                                <li>
                                    <b>fnameRU</b> <b className="maxlength">[max length: 255]</b> - имя (RU) <span>обязательное поле</span>
                                </li>
                                <li>
                                    <b>lnameRU</b> <b className="maxlength">[max length: 255]</b> - фамилия (RU) <span>обязательное поле</span>
                                </li>
                                <li>
                                    <b>mnameRU</b> <b className="maxlength">[max length: 255]</b> - отчество (RU) <span>обязательное поле</span>
                                </li>
                                <li>
                                    <b>fnameENG</b> <b className="maxlength">[max length: 255]</b> - имя (ENG)
                                </li>
                                <li>
                                    <b>lnameENG</b> <b className="maxlength">[max length: 255]</b> - фамилия (ENG)
                                </li>
                                <li>
                                    <b>mnameENG</b> <b className="maxlength">[max length: 255]</b> - отчество (ENG)
                                </li>
                                <li>
                                    <b>email</b> <b className="maxlength">[max length: 255]</b> - email <span>обязательное поле</span>
                                </li>
                                <li>
                                    <b>bday</b> - дата рождения <span>обязательное поле</span>
                                </li>
                                <li>
                                    <b>phone</b> <b className="maxlength">[max length: 20]</b> - номер телефона <span>обязательное поле</span>
                                </li>
                                <li>
                                    <b>passportID</b> <b className="maxlength">[max length: 20]</b> - номер паспорта
                                </li>
                                <li>
                                    <b>passportSeries</b> <b className="maxlength">[max length: 10]</b> - серия паспорта
                                </li>
                                <li>
                                    <b>regAddress</b> - адрес регистрации
                                </li>
                                <li>
                                    <b>passportIssued</b> - дата выдачи паспорта
                                </li>
                                <li>
                                    <b>departCode</b> <b className="maxlength">[max length: 10]</b> - код подразделения
                                </li>
                                <li>
                                    <b>whoIssued</b> - кем выдан паспорта
                                </li>
                                <li>
                                    <b>oms</b> <b className="maxlength">[max length: 255]</b> - полис ОМС №
                                </li>
                                <li>
                                    <b>snils</b> <b className="maxlength">[max length: 255]</b> - СНИЛС №
                                </li>
                                <li>
                                    <b>pfdo</b> <b className="maxlength">[max length: 255]</b> - ПФДО №
                                </li>
                                <li>
                                    <b>citizenship</b> <b className="maxlength">[max length: 255]</b> - гражданство
                                </li>
                                <li>
                                    <b>federalDist</b> <b className="maxlength">[max length: 255]</b> - федеральный округ
                                </li>
                                <li>
                                    <b>subject</b> <b className="maxlength">[max length: 255]</b> - субъект
                                </li>
                                <li>
                                    <b>city</b> <b className="maxlength">[max length: 255]</b> - город
                                </li>
                                <li>
                                    <b>resAddress</b> - адрес проживания
                                </li>
                                <li>
                                    <b>familyStatus</b> <b className="maxlength">[max length: 255]</b> - социальный статус семьи
                                </li>
                                <li>
                                    <b>parentBday</b> - дата рождения родителя
                                </li>
                                <li>
                                    <b>fnameParent</b> <b className="maxlength">[max length: 255]</b> - Фамилия родителя
                                </li>
                                <li>
                                    <b>lnameParent</b> <b className="maxlength">[max length: 255]</b> - Имя родителя
                                </li>
                                <li>
                                    <b>mnameParent</b> <b className="maxlength">[max length: 255]</b> - Отчество родителя
                                </li>
                                <li>
                                    <b>genderParent</b> <b className="maxlength">[max length: 1]</b> - Пол родителя (М/Ж)
                                </li>
                                <li>
                                    <b>props</b> - массив дополнительных свойств пользователя (json объект форматированный в строку)
                                </li>
                            </ul>
                        </li>
                    </ul>
                </>,
                "code": {
                    "fnameRU": "имя (RU)",
                    "lnameRU": "фамилия (RU)",
                    "mnameRU": "отчество (RU)",
                    "fnameENG": "имя (ENG)",
                    "lnameENG": "фамилия (ENG)",
                    "mnameENG": "отчество (ENG)",
                    "email": "info@enjoinsport.com",
                    "bday": "2024-01-30",
                    "phone": "79030000000",
                    "passportID": "000000",
                    "passportSeries": "0000",
                    "regAddress": "адрес регистрации",
                    "passportIssued": "2024-01-30",
                    "departCode": "код",
                    "whoIssued": "кем выдан паспорта",
                    "oms": "0000000000000000",
                    "snils": "000-000-000-00",
                    "pfdo": "ПФДО №",
                    "citizenship": "гражданство",
                    "federalDist": "федеральный округ",
                    "subject": "субъект",
                    "city": "город",
                    "resAddress": "адрес проживания",
                    "familyStatus": "социальный статус семьи",
                    "parentBday": "2024-01-30",
                    "fnameParent": "фамилия родителя",
                    "lnameParent": "имя родителя",
                    "mnameParent": "отчество родителя",
                    "genderParent": "М",
                    "props": "{\\\"props\\\": \\\"value\\\"}"
                },
                "response": {
                    "error": false,
                    "msg": "Пользователь обновлен",
                }
            },
            {
                "title": "Спискок пользователей",
                "desc": <>
                    <p>
                        Для выполнения запроса в header необходимо передать ключ выданный администратором в момент заключения договора <b>(Authorization: Token Ваш_токен)</b>.
                    </p>
                    <p>
                        Для фильтрации списка передайте в GET следующие параметры:
                    </p>

                    <ul>
                        <li>
                            <b>email</b> - Email
                        </li> 
                        <li>
                            <b>lname</b> - Фамилия
                        </li>
                        <li>
                            <b>fname</b> - Имя
                        </li>
                        <li>
                            <b>mname</b> - Отчество
                        </li>
                    </ul>
                        
                    <p>
                        Данный метод поддерживает 2 вида вывода данных, а именно полный и сокращенный. Сокращенный вариант выводит следующий список полей:
                    </p>

                    <ul>
                        <li>
                            <b>userID</b> - ID пользователя 
                        </li>
                        <li>
                            <b>date</b> - Дата регистрации 
                        </li>
                        <li>
                            <b>lnameRU</b> - Фамилия
                        </li>
                        <li>
                            <b>fnameRU</b> - Имя
                        </li>
                        <li>
                            <b>mnameRU</b> - Отчество
                        </li>
                        <li>
                            <b>email</b> - Email
                        </li>
                    </ul>
                    <p>Полный вид поддерживает вывод всех полей пользователя. Для выбора типа вывода данных необходимо передать в GET переменной параметр <b>type</b> со следующими параметрами:</p>
                    <ul>
                        <li>
                            <b>0</b> - Сокращенный вид
                        </li>
                        <li>
                            <b>1</b> - Полный вид
                        </li>
                    </ul>

                    <p>Для построения постраничной навигации необходимо передать в GET переменной параметры:</p>
                    <ul>
                        <li>
                            <b>page</b> - целевая страница
                        </li>
                        <li>
                            <b>count</b> - количество записей на странице
                        </li>
                    </ul>
                    <p>
                        В случае неуспеха, метод вернет error=true и описание ошибки
                    </p>
                    <ul>
                        <li>
                            <b>URL: </b>https://api.enjoinsport.ru/user/?email=&lname=&fname=&mname=&type=&page=&count=
                        </li>
                        <li>
                            <b>Метод: </b>GET
                        </li>
                    </ul>
                </>,
                "response": {
                    "error": false,
                    "list":[
                        {
                            "userID": 1,
                            "date": "Дата регистрации",
                            "lnameRU": "Фамилия",
                            "fnameRU": "Имя",
                            "mnameRU": "Отчество",
                            "email": "info@enjoinsport.ru"
                        },
                        {
                            "userID": 2,
                            "date": "Дата регистрации",
                            "lnameRU": "Фамилия",
                            "fnameRU": "Имя",
                            "mnameRU": "Отчество",
                            "email": "info@enjoinsport.ru"
                        }
                    ],
                    "pageCount": 2
                }
            },
            {
                "title": "Поиск пользователя по userID",
                "desc": <>
                    <p>
                        Для выполнения запроса в header необходимо передать ключ выданный администратором в момент заключения договора <b>(Authorization: Token Ваш_токен)</b>.
                    </p>
                    <p>
                        В случае неуспеха, метод вернет error=true и описание ошибки
                    </p>
                    <ul>
                        <li>
                            <b>URL: </b>https://api.enjoinsport.ru/user/<span className="url-props">[userID]</span>/
                        </li>
                        <li>
                            <b>Метод: </b>GET
                        </li>
                    </ul>
                </>,
                "response": {
                    "error": false,
                    "item": {
                        "userID": 1,
                        "fnameRU": "имя (RU)",
                        "lnameRU": "фамилия (RU)",
                        "mnameRU": "отчество (RU)",
                        "fnameENG": "имя (ENG)",
                        "lnameENG": "фамилия (ENG)",
                        "mnameENG": "отчество (ENG)",
                        "email": "info@enjoinsport.com",
                        "bday": "2024-01-30",
                        "phone": "79030000000",
                        "passportID": "000000",
                        "passportSeries": "0000",
                        "regAddress": "адрес регистрации",
                        "passportIssued": "2024-01-30",
                        "departCode": "код",
                        "whoIssued": "кем выдан паспорта",
                        "oms": "0000000000000000",
                        "snils": "000-000-000-00",
                        "pfdo": "ПФДО №",
                        "citizenship": "гражданство",
                        "federalDist": "федеральный округ",
                        "subject": "субъект",
                        "city": "город",
                        "resAddress": "адрес проживания",
                        "familyStatus": "социальный статус семьи",
                        "parentBday": "2024-01-30",
                        "fnameParent": "фамилия родителя",
                        "lnameParent": "имя родителя",
                        "mnameParent": "отчество родителя",
                        "genderParent": "М",
                        "props": "{\\\"props\\\": \\\"value\\\"}"
                    }
                }
            },
            {
                "title": "Удаление пользователя",
                "desc": <>
                    <p>
                        Для выполнения запроса в header необходимо передать ключ выданный администратором в момент заключения договора <b>(Authorization: Token Ваш_токен)</b>.
                    </p>
                    <p>
                        В случае неуспеха, метод вернет error=true и описание ошибки
                    </p>
                    <p>
                        <b className="warning">Удаление пользователей также удаляет все связанные записи, а именно (Страховки, подписки и тд)</b>
                    </p>
                    <ul>
                        <li>
                            <b>URL: </b>https://api.enjoinsport.ru/user/<span className="url-props">[userID]</span>/
                        </li>
                        <li>
                            <b>Метод: </b>DELETE
                        </li>
                    </ul>
                </>,
                "response": {
                    "error": false,
                    "msg" : "Пользователь удален"
                }
            }
        ]
    }, 
    {
        "title": "Членские взносы",
        "desc": <>
            <p>В процессе работы с подписками можно реализовать следующий набор действий, а именно:</p>
            <ul>
                <li>Создание членского взноса</li>
                <li>Вывод списка взносов</li>
                <li>Поиск взноса по id</li>
            </ul>
            <p>
                <b className="warning">Для выполнения данного типа запросов необходимо заполнить настройки интеграции tinkoff в ЛК партнера</b>
            </p>
        </>,
        "children": [
            {
                "title": "Создание подписки",
                "desc": <>
                    <p>
                        Для выполнения запроса в header необходимо передать ключ выданный администратором в момент заключения договора <b>(Authorization: Token Ваш_токен)</b>.
                    </p>
                    <p>
                        В случае неуспеха, метод вернет error=true и описание ошибки
                    </p>
                    <ul>
                        <li>
                            <b>URL: </b>https://api.enjoinsport.ru/subscribe/
                        </li>
                        <li>
                            <b>Метод: </b>GET
                        </li>
                        <li className="developer-block__props">
                            <b>Свойства:</b>
                            <ul>
                                <li>
                                    <b>orderID</b> - id заказа в Вашей системе (Уникальный идентификатор) <span>обязательное поле</span>
                                </li>
                                <li>
                                    <b>userID</b> - id пользователя ранее загруженного через метод /user/ <span>обязательное поле</span>
                                </li>
                                <li>
                                    <b>subscriptionEnd</b> - Дата завершения подписки (включительно) <span>обязательное поле</span>
                                </li>
                                <li>
                                    <b>price</b> - цена (в копейках) <span>обязательное поле</span>
                                </li>
                                <li>
                                    <b>userType</b> <b className="maxlength">[max length: 255]</b> - тип пользователя
                                </li>
                            </ul>
                        </li>
                    </ul>
                </>,
                "code": {
                    "orderID": 100,
                    "userID": 100,
                    "subscriptionEnd": "2024-01-30",
                    "price": 1000,
                    "userType": "студент"
                },
                "response": {
                    "error": false,
                    "res": {
                        "Success": true,
                        "ErrorCode": "0",
                        "TerminalKey": "000000000",
                        "Status": "NEW",
                        "PaymentId": "0000000000",
                        "OrderId": "100",
                        "Amount": 1000,
                        "PaymentURL": "https://securepayments.tinkoff.ru/"
                    }
                }
            },
            {
                "title": "Callback после оплаты",
                "desc": <>
                    <p>После успешной или отмененной оплаты на указанный в рамках ЛК endpoint поступит запрос со статусом выполнения оплаты.</p>
                    <ul>
                        <li>
                            <b>Метод: </b>POST
                        </li>
                    </ul>
                </>,
                "response": {
                    "TerminalKey": "0000000",
                    "OrderId": "1",
                    "Success": true,
                    "PaymentId": "123"
                }
            },
            {
                "title": "Список платежей",
                "desc": <>
                    <p>Для выполнения запроса в header необходимо передать ключ выданный администратором в момент заключения договора (Authorization: Token Ваш_токен).</p>
                    <p>Для построения постраничной навигации необходимо передать в GET переменной параметры:</p>
                    <ul>
                        <li>
                            <b>page</b> - целевая страница
                        </li>
                        <li>
                            <b>count</b> - количество записей на странице
                        </li>
                    </ul>

                    <p>В случае неуспеха, метод вернет error=true и описание ошибки</p>

                    <ul>
                        <li>
                            <b>URL: </b>https://api.enjoinsport.ru/subscribe/?&page=&count=
                        </li>
                        <li>
                            <b>Метод: </b>GET
                        </li>
                    </ul>
                </>,
                "response": {
                    "error": false,
                    "list": [
                        {
                            "orderID": "111",
                            "paymentId": "000000",
                            "user": 1,
                            "dateCreate": "2024-02-06T14:28:24.921701Z",
                            "status": "Completed",
                            "subscriptionEnd": "2024-12-31",
                            "price": 1000,
                            "userType": "Премиум"
                        }
                    ],
                    "pageCount": 1
                }
            },
            {
                "title": "Поиск платежа по orderID",
                "desc": <>
                    <p>Для выполнения запроса в header необходимо передать ключ выданный администратором в момент заключения договора (Authorization: Token Ваш_токен).</p>
                    <p>В случае неуспеха, метод вернет error=true и описание ошибки</p>
                    <ul>
                        <li><b>URL:</b> https://api.enjoinsport.ru/subscribe/[orderID]/</li>
                        <li><b>Метод:</b> GET</li>
                    </ul>
                </>,
                "response": {
                    "error": false,
                    "item": {
                        "orderID": "111",
                        "paymentId": "000000",
                        "user": 24151,
                        "dateCreate": "2024-02-06T14:28:24.921701Z",
                        "status": "Completed",
                        "subscriptionEnd": "2024-12-31",
                        "price": 1000,
                        "userType": "Премиум"
                    }
                }
            }
        ]
    },
    {
        "title": "Страховки",
        "desc": <>
            <p>В рамках подключения страхования необходимо выполнить следующий набор запросов:</p>
            <p>
                <img style={{textAlign: "center", borderRadius: 10}} src={insurance} />
            </p>
            <p>
                Справочники:
            </p>
            <ul>
                <li>
                    <Link to="/developers/sports/" target="_blank">Виды спорта</Link>
                </li>
                <li>
                    <Link to="/developers/periods/" target="_blank">Период страховки</Link>
                </li>
                <li>
                    <Link to="/developers/limits/" target="_blank">Лимиты страховки</Link>
                </li>
            </ul>
        </>,
        "children": [
            {
                "title": "Список стаховок",
                "desc": <>
                    <p>Для выполнения запроса в header необходимо передать ключ выданный администратором в момент заключения договора (Authorization: Token Ваш_токен).</p>

                    <p>Для построения постраничной навигации необходимо передать в GET переменной параметры:</p>
                    <ul>
                        <li>
                            <b>page</b> - целевая страница
                        </li>
                        <li>
                            <b>count</b> - количество записей на странице
                        </li>
                    </ul>
                    <p>В случае неуспеха, метод вернет error=true и описание ошибки</p>
                    <ul>
                        <li>
                            <b>URL: </b>https://api.enjoinsport.ru/subscribe/?&page=&count=
                        </li>
                        <li>
                            <b>Метод: </b>GET
                        </li>
                    </ul>
                </>,
                "response": {
                    "error": false,
                    "list": [
                        {
                            "id": 4,
                            "mid": "07029e25e75ada80b03bce67ed765e9f",
                            "status": "waitingPay",
                            "startTime": "2024-02-23",
                            "endTime": "2024-05-23",
                            "time": 90,
                            "price": 30000000,
                            "sportType": "48",
                            "partner": 4,
                            "user": 673
                        }
                    ],
                    "pageCount": 1
                }
            },
            {
                "title": "Список стаховок пользователя",
                "desc": <>
                    <p>Данный метод выводит список истории страховок пользователя по <b>userID</b></p>
                    <p>В случае неуспеха, метод вернет error=true и описание ошибки</p>
                    <ul>
                        <li>
                            <b>URL: </b>https://api.enjoinsport.ru/subscribe/[userID]/
                        </li>
                        <li>
                            <b>Метод: </b>GET
                        </li>
                    </ul>
                </>,
                "response": {
                    "error": false,
                    "list": [
                        {
                            "id": 4,
                            "mid": "07029e25e75ada80b03bce67ed765e9f",
                            "status": "waitingPay",
                            "startTime": "2024-02-23",
                            "endTime": "2024-05-23",
                            "time": 90,
                            "price": 30000000,
                            "sportType": "48",
                            "partner": 4,
                            "user": 673
                        }
                    ]
                }
            },
            {
                "title": "Просчет стоимости страховки",
                "desc": <>
                    <p>Для расчета стоимости страховки необходимо выполнить первичный расчет строимости страховски. Для этого необходимо выполнить следующий запрос:</p>
                    <p>
                        В случае неуспеха, метод вернет error=true и описание ошибки
                    </p>
                    <ul>
                        <li>
                            <b>URL:</b> https://api.enjoinsport.ru/calc-insurance/
                        </li>
                        <li>
                            <b>Метод:</b> POST
                        </li>
                        <li className="developer-block__props">
                            <b>Свойства:</b>
                            <ul>
                                <li>
                                    <b>userID</b> - id пользователя ранее загруженного через метод /user/ <span>обязательное поле</span>
                                </li>
                                <li>
                                    <b>sport_id</b> - id вида спорта (из справочника <Link to="/developers/sports/" target="_blank">виды спорта</Link>) <span>обязательное поле</span>
                                </li>
                                <li>
                                    <b>period</b> - период страховки (из справочника <Link to="/developers/periods/" target="_blank">период страховки</Link>) <span>обязательное поле</span>
                                </li>
                                <li>
                                    <b>contract_sum</b> - страховая сумма (из справочника <Link to="/developers/limits/" target="_blank">лимиты страховки</Link>) <span>обязательное поле</span>
                                </li>
                            </ul>
                        </li>

                    </ul>
                </>,
                "code": {
                    "userID": 1,
                    "sport_id": 1,
                    "period": 30,
                    "contract_sum": 30000
                },
                "response": {
                    "error": false,
                    "premium": "540.00",
                    "calc_id": 1,
                    "mid": "0000000000"
                }
            },
            {
                "title": "Создание страховки",
                "desc": <>
                    <p>Метод вызывается, если клиент хочет оформить полис.</p>
                    <p>Данный метод нужно вызывать после выполнения запроса /calc-insurance/ и успешного получения <b>mid</b></p>
                    <p>
                        <b className="warning">В случае успешного выполнения запроса вы получите протатип страхового полиса для данной заявки</b>
                    </p>
                    <p>
                        В случае неуспеха, метод вернет error=true и описание ошибки
                    </p>
                
                    <ul>
                        <li>
                            <b>URL:</b> https://api.enjoinsport.ru/save-insurance/
                        </li>
                        <li>
                            <b>Метод:</b> POST
                        </li>
                        <li className="developer-block__props">
                            <b>Свойства:</b>
                            <ul>
                                <li>
                                    <b>userID</b> - id пользователя ранее загруженного через метод /user/ <span>обязательное поле</span>
                                </li>
                                <li>
                                    <b>mid</b> - идентификатор заявки полученный в результате вызова метода /calc-insurance/ <span>обязательное поле</span>
                                </li>
                                <li>
                                    <b>sport_id</b> - id вида спорта ранее переданный в метод /calc-insurance/ (из справочника <Link to="/developers/sports/" target="_blank">виды спорта</Link>) <span>обязательное поле</span>
                                </li>
                                <li>
                                    <b>period</b> - период страховки ранее переданный в метод /calc-insurance/ (из справочника <Link to="/developers/periods/" target="_blank">период страховки</Link>) <span>обязательное поле</span>
                                </li>
                                <li>
                                    <b>contract_sum</b> - страховая сумма ранее переданная в метод /calc-insurance/ (из справочника <Link to="/developers/limits/" target="_blank">лимиты страховки</Link>) <span>обязательное поле</span>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </>,
                "code": {
                    "userID": 1,
                    "mid": "12",
                    "sport_id": 1,
                    "period": 30,
                    "contract_sum": 30000
                },
                "response": {
                    "error": false,
                    "mid": "12",
                    "contract_id": 1
                }
            },
            {
                "title": "Получение файла договора (PDF)",
                "desc": <>
                    <p>Данный метод нужно вызывать для получения прототипа PDF договора или для получения итогового документа после выполнения оплаты.</p>
                    <p>
                        В случае неуспеха, метод вернет error=true и описание ошибки
                    </p>
                    <ul>
                        <li>
                            <b>URL:</b> https://api.enjoinsport.ru/getpdf-insurance/
                        </li>
                        <li>
                            <b>Метод:</b> POST
                        </li>
                        <li className="developer-block__props">
                            <b>Свойства:</b>
                            <ul>
                                <li>
                                    <b>userID</b> - id пользователя ранее загруженного через метод /user/ <span>обязательное поле</span>
                                </li>
                                <li>
                                    <b>mid</b> - идентификатор заявки полученный в результате вызова метода /save-insurance/ <span>обязательное поле</span>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </>,
                "code": {
                    "userID": 1,
                    "mid": "12"
                },
                "response": {
                    "error": false,
                    "pdf": "base64 string"
                }
            },
            {
                "title": "Формирование платежной ссылки",
                "desc": <>
                    <p>Данный запрос возвращает ссылку на оплату по ранее сформированному <b>mid</b> в рамках метода /save-insurance/</p>
                    <p>
                        В случае неуспеха, метод вернет error=true и описание ошибки.
                    </p>
                    <ul>
                        <li>
                            <b>URL:</b> https://api.enjoinsport.ru/paylink-insurance/
                        </li>
                        <li>
                            <b>Метод:</b> POST
                        </li>
                        <li className="developer-block__props">
                            <b>Свойства:</b>
                            <ul>
                                <li>
                                    <b>userID</b> - id пользователя ранее загруженного через метод /user/ <span>обязательное поле</span>
                                </li>
                                <li>
                                    <b>mid</b> - идентификатор заявки полученный в результате вызова метода /save-insurance/ <span>обязательное поле</span>
                                </li>
                                <li>
                                    <b>success_url</b> - ссылка на которую осуществляется переход после успешной оплаты <span>обязательное поле</span>
                                </li>
                                <li>
                                    <b>fail_url</b> - ссылка на которую осуществляется переход в случае ошибки оплаты <span>обязательное поле</span>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </>,
                "code": {
                    "userID": 1,
                    "mid": "12",
                    "success_url": "url",
                    "fail_url": "url"
                },
                "response": {
                    "error": false,
                    "contract_premium": "-",
                    "pay_url": "https://api.enjoinsport.ru/"
                }
            },
            {
                "title": "Проверка статуса оплаты",
                "desc": <>
                    <p>
                        После оплаты используя метод /paylink-insurance/ необходимо проверить статус операции по ранее сформированному <b>mid</b> в рамках метода /save-insurance/
                    </p>
                    <p>
                        В случае неуспеха, метод вернет error=true и описание ошибки
                    </p>
                    <p>
                        <b className="warning">В случае возникновения ошибки необходимо вызвать метод /paylink-insurance/ который вернет актуальную ссылку на оплату или закроет оплату если ссылка уже не работает. В противном случае метод /save-insurance/ будет возвращать ссылку на открытый запрос страхования</b>
                    </p>
                    <ul>
                        <li>
                            <b>URL:</b> https://api.enjoinsport.ru/checkpay-insurance/
                        </li>
                        <li>
                            <b>Метод:</b> POST
                        </li>
                        <li className="developer-block__props">
                            <b>Свойства:</b>
                            <ul>
                                <li>
                                    <b>userID</b> - id пользователя ранее загруженного через метод /user/ <span>обязательное поле</span>
                                </li>
                                <li>
                                    <b>mid</b> - идентификатор заявки полученный в результате вызова метода /save-insurance/ <span>обязательное поле</span>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </>,
                "code": {
                    "userID": 1,
                    "mid": "12"
                },
                "response": {
                    "status": 9,
                    "error": false,
                    "message": "Оплачен",
                    "cache": true,
                    "contract_number": "12"
                }
            }
        ]
    },
    {
        "title": "Документы",
        "desc": <>
            <p>В рамках подключения работы с документами необходимо выполнить следующий набор запросов:</p>
            <ul>
                <li>
                    Получение списка документов
                </li> 
                <li>
                    Получение списка документов пользователя
                </li> 
                <li>
                    Создание документа
                </li> 
                <li>
                    Редактирование документа
                </li> 
                <li>
                    Удаление документа
                </li> 
            </ul>
        </>,
        "children": [
            {
                "title": "Список документов",
                "desc": <>
                    <p>Для выполнения запроса в header необходимо передать ключ выданный администратором в момент заключения договора (Authorization: Token Ваш_токен).</p>
        
                    <p>Для построения постраничной навигации необходимо передать в GET переменной параметры:</p>
                    <ul>
                        <li>
                            <b>page</b> - целевая страница
                        </li>
                        <li>
                            <b>count</b> - количество записей на странице
                        </li>
                    </ul>
                    <p>В случае неуспеха, метод вернет error=true и описание ошибки</p>
                    <ul>
                        <li>
                            <b>URL: </b>https://api.enjoinsport.ru/documents/?&page=&count=
                        </li>
                        <li>
                            <b>Метод: </b>GET
                        </li>
                    </ul>
                </>,
                "response": {
                    "error":false,
                    "errorkey":0,
                    "list":[
                        {
                            "id": 0,
                            "title": "Название",
                            "issue": "2024-05-22",
                            "whoIssue": "Кто выдал",
                            "file": "Ссылка на файл",
                            "partner": 0,
                            "user": 0
                        }
                    ],
                    "pageCount":1
                }
            },
            {
                "title": "Список документов по userID",
                "desc": <>
                    <p>Для выполнения запроса в header необходимо передать ключ выданный администратором в момент заключения договора (Authorization: Token Ваш_токен).</p>
    
                    <p>В случае неуспеха, метод вернет error=true и описание ошибки</p>
                    <ul>
                        <li>
                            <b>URL: </b>https://api.enjoinsport.ru/documents/<span className="url-props">[userID]</span>/
                        </li>
                        <li>
                            <b>Метод: </b>GET
                        </li>
                    </ul>
                </>,
                "response": {
                    "error":false,
                    "errorkey":0,
                    "list":[
                        {
                            "id": 0,
                            "title": "Название",
                            "issue": "2024-05-22",
                            "whoIssue": "Кто выдал",
                            "file": "Ссылка на файл",
                            "partner": 0,
                            "user": 0
                        }
                    ],
                    "pageCount":1
                }
            },
            {
                "title": "Создание документа",
                "desc": <>
                    <p>Для выполнения запроса в header необходимо передать ключ выданный администратором в момент заключения договора (Authorization: Token Ваш_токен).</p>
    
                    <p>В случае неуспеха, метод вернет error=true и описание ошибки</p>

                    <ul>
                        <li>
                            <b>URL: </b>https://api.enjoinsport.ru/documents/?userID=
                        </li>
                        <li>
                            <b>Метод: </b>POST
                        </li>
                        <li>
                            <b>GET свойства:</b>
                            <ul>
                                <li>
                                    <b>userID</b> - id пользователя
                                </li>
                            </ul>
                        </li>
                        <li>
                            <b>Свойства:</b>
                            <ul>
                                <li><b>title</b> - Название документа <span>обязательное поле</span></li>
                                <li><b>issue</b> - Дата выдачи <span>обязательное поле</span></li>
                                <li><b>whoIssue</b> - Кто выдал <span>обязательное поле</span></li>
                                <li><b>file</b> - Файл документа (jpg, png)</li>
                            </ul>
                        </li>
                    </ul>
                </>,
                "code": {
                    "title": "Название документа",
                    "issue": "2024-12-24",
                    "whoIssue": "Кто выдал",
                    "file": "file"
                },
                "response": {
                    "error": true,
                    "errorkey": 0,
                    "msg": "Документ создан"
                }
            },
            {
                "title": "Обновление документа",
                "desc": <>
                    <p>Для выполнения запроса в header необходимо передать ключ выданный администратором в момент заключения договора (Authorization: Token Ваш_токен).</p>
    
                    <p>В случае неуспеха, метод вернет error=true и описание ошибки</p>

                    <ul>
                        <li>
                            <b>URL: </b>https://api.enjoinsport.ru/documents/<span className="url-props">[ID]</span>/
                        </li>
                        <li>
                            <b>Метод: </b>PUT
                        </li>
                        <li>
                            <b>Свойства:</b>
                            <ul>
                                <li><b>title</b> - Название документа <span>обязательное поле</span></li>
                                <li><b>issue</b> - Дата выдачи <span>обязательное поле</span></li>
                                <li><b>whoIssue</b> - Кто выдал <span>обязательное поле</span></li>
                                <li><b>file</b> - Файл документа (jpg, png)</li>
                            </ul>
                        </li>
                    </ul>
                </>,
                "code": {
                    "title": "Название документа",
                    "issue": "2024-12-24",
                    "whoIssue": "Кто выдал",
                    "file": "file"
                },
                "response": {
                    "error": true,
                    "errorkey": 0,
                    "msg": "Документ обновлен"
                }
            },
            {
                "title": "Удаление документа",
                "desc": <>
                    <p>Для выполнения запроса в header необходимо передать ключ выданный администратором в момент заключения договора (Authorization: Token Ваш_токен).</p>
    
                    <p>В случае неуспеха, метод вернет error=true и описание ошибки</p>

                    <p>
                        Для выполнения запроса необходимо передать <b>ID</b> документа. <b>ID</b> можно получить используя метод "Список документов".
                    </p>
                    <ul>
                        <li>
                            <b>URL: </b>https://api.enjoinsport.ru/documents/<span className="url-props">[id]</span>/
                        </li>
                        <li>
                            <b>Метод: </b>DELETE
                        </li>
                    </ul>
                </>,
                "response": {
                    "error": false,
                    "errorkey": 0,
                    "msg" : "Документ удален"
                }
            }
        ]
    },
    {
        "title": "Спорт инвентарь",
        "desc": <>
            <p>В рамках подключения работы со спорт инвентарем необходимо выполнить следующий набор запросов:</p>
            <ul>
                <li>Получение списка спорт инвентаря</li>
                <li>Получение списка спорт инвентаря пользователя</li>
                <li>Создание спорт инвентаря</li>
                <li>Редактирование спорт инвентаря</li>
                <li>Удаление спорт инвентаря</li>
            </ul>
        </>,
        "children": [
            {
                "title": "Список спорт инвентаря",
                "desc": <>
                    <p>Для выполнения запроса в header необходимо передать ключ выданный администратором в момент заключения договора (Authorization: Token Ваш_токен).</p>
                    <p>Для построения постраничной навигации необходимо передать в GET переменной параметры:</p>
                    <ul>
                        <li>
                            <b>page</b> - целевая страница
                        </li>
                        <li>
                            <b>count</b> - количество записей на странице
                        </li>
                    </ul>
                    <p>В случае неуспеха, метод вернет error=true и описание ошибки</p>
                    <ul>
                        <li>
                            <b>URL: </b>https://api.enjoinsport.ru/inventory/?&page=&count=
                        </li>
                        <li>
                            <b>Метод: </b>GET
                        </li>
                    </ul>
                </>,
                "response": {
                    "error": false,
                    "errorkey": 0,
                    "list": [
                        {
                            "id": 1,
                            "itemClass": "Класс",
                            "itemType": "Тип",
                            "title": "Название",
                            "nationalCode": "Код",
                            "number": "Номер",
                            "length": "Длина",
                            "height": "Высота",
                            "width": "Ширина",
                            "weight": "Вес",
                            "release": "Год выпуска",
                            "serialNumber": "Номер",
                            "owner": "Собственник",
                            "partner": 0,
                            "user": 0
                        }
                    ],
                    "pageCount": 1
                }
            },
            {
                "title": "Список спорт инвентаря по userID",
                "desc": <>
                    <p>Для выполнения запроса в header необходимо передать ключ выданный администратором в момент заключения договора (Authorization: Token Ваш_токен).</p>
                    <p>В случае неуспеха, метод вернет error=true и описание ошибки</p>
                    <ul>
                        <li>
                            <b>URL: </b>https://api.enjoinsport.ru/inventory/<span className="url-props">[userID]</span>/
                        </li>
                        <li>
                            <b>Метод: </b>GET
                        </li>
                    </ul>
                </>,
                "response": {
                    "error": false,
                    "errorkey": 0,
                    "list": [
                        {
                            "id": 1,
                            "itemClass": "Класс",
                            "itemType": "Тип",
                            "title": "Название",
                            "nationalCode": "Код",
                            "number": "Номер",
                            "length": "Длина",
                            "height": "Высота",
                            "width": "Ширина",
                            "weight": "Вес",
                            "release": "Год выпуска",
                            "serialNumber": "Номер",
                            "owner": "Собственник",
                            "partner": 0,
                            "user": 0
                        }
                    ],
                    "pageCount": 1
                }
            },
            {
                "title": "Создание спорт инвентаря",
                "desc": <>
                    <p>Для выполнения запроса в header необходимо передать ключ выданный администратором в момент заключения договора (Authorization: Token Ваш_токен).</p>
                    <p>В случае неуспеха, метод вернет error=true и описание ошибки</p>
                    <ul>
                        <li>
                            <b>URL:</b> https://api.enjoinsport.ru/inventory/?userID=
                        </li>
                        <li>
                            <b>Метод:</b> POST
                        </li>
                        <li>
                            <b>GET свойства:</b>
                            <ul>
                                <li>
                                    <b>userID</b> - id пользователя
                                </li>
                            </ul>
                        </li>
                        <li className="developer-block__props">
                            <b>Свойства:</b>
                            <ul>
                                <li><b>itemClass</b> - класс <span>обязательное поле</span></li>
                                <li><b>itemType</b> - тип <span>обязательное поле</span></li>
                                <li><b>title</b> - название <span>обязательное поле</span></li>
                                <li><b>nationalCode</b> - национальный код</li>
                                <li><b>number</b> - номер <span>обязательное поле</span></li>
                                <li><b>length</b> - длина</li>
                                <li><b>height</b> - высота</li>
                                <li><b>width</b> - ширина</li> 
                                <li><b>weight</b> - вес</li>
                                <li><b>release</b> - год выпуска</li>
                                <li><b>serialNumber</b> - серийный номер</li>
                                <li><b>owner</b> - собственник <span>обязательное поле</span></li>
                            </ul>
                        </li>
                    </ul>
                </>,
                "code": {
                    "itemClass": "класс",
                    "itemType": "тип",
                    "title": "название",
                    "nationalCode": "национальный код",
                    "number": "номер",
                    "length": "длина",
                    "height": "высота",
                    "width": "ширина",
                    "weight": "вес",
                    "release": "год выпуска",
                    "serialNumber": "серийный номер",
                    "owner": "собственник"
                },
                "response": {
                    "error": false,
                    "errorkey": 0,
                    "msg" : "Спорт инвентарь создан"
                }
            },
            {  
                "title": "Обновление спорт инвентаря",
                "desc": <>
                    <p>Для выполнения запроса в header необходимо передать ключ выданный администратором в момент заключения договора (Authorization: Token Ваш_токен).</p>
                    <p>В случае неуспеха, метод вернет error=true и описание ошибки</p>
                    <ul>
                        <li>
                            <b>URL:</b> https://api.enjoinsport.ru/documents/<span className="url-props">[ID]</span>/
                        </li>
                        <li>
                            <b>Метод:</b> PUT
                        </li>
                        <li className="developer-block__props">
                            <b>Свойства</b>
                            <ul>
                                <li><b>itemClass</b> - класс <span>обязательное поле</span></li>
                                <li><b>itemType</b> - тип <span>обязательное поле</span></li>
                                <li><b>title</b> - название <span>обязательное поле</span></li>
                                <li><b>nationalCode</b> - национальный код</li>
                                <li><b>number</b> - номер <span>обязательное поле</span></li>
                                <li><b>length</b> - длина</li>
                                <li><b>height</b> - высота</li>
                                <li><b>width</b> - ширина</li> 
                                <li><b>weight</b> - вес</li>
                                <li><b>release</b> - год выпуска</li>
                                <li><b>serialNumber</b> - серийный номер</li>
                                <li><b>owner</b> - собственник <span>обязательное поле</span></li>
                            </ul>
                        </li>
                    </ul>
                </>,
                "code": {
                    "itemClass": "класс",
                    "itemType": "тип",
                    "title": "название",
                    "nationalCode": "национальный код",
                    "number": "номер",
                    "length": "длина",
                    "height": "высота",
                    "width": "ширина",
                    "weight": "вес",
                    "release": "год выпуска",
                    "serialNumber": "серийный номер",
                    "owner": "собственник"
                },
                "response": {
                    "error": false,
                    "errorkey": 0,
                    "msg" : "Спорт инвентарь обновлен"
                }
            },
            {
                "title": "Удаление спорт инвентаря",
                "desc": <>
                    <p>Для выполнения запроса в header необходимо передать ключ выданный администратором в момент заключения договора (Authorization: Token Ваш_токен).</p>
    
                    <p>В случае неуспеха, метод вернет error=true и описание ошибки</p>

                    <p>
                        Для выполнения запроса необходимо передать <b>ID</b> спорт инвентаря. <b>ID</b> можно получить используя метод "Список спорт инвентаря".
                    </p>
                    <ul>
                        <li>
                            <b>URL: </b>https://api.enjoinsport.ru/inventory/<span className="url-props">[id]</span>/
                        </li>
                        <li>
                            <b>Метод: </b>DELETE
                        </li>
                    </ul>
                </>,
                "response": {
                    "error": false,
                    "errorkey": 0,
                    "msg" : "Спорт инвентарь удален"
                }
            },
        ]
    }
]

export const paySports = [
    {
        "id": "1",
        "title": "Авиамодельный спорт"
    },
    {
        "id": "2",
        "title": "Бадминтон"
    },
    {
        "id": "3",
        "title": "Бильярдный спорт"
    },
    {
        "id": "4",
        "title": "Бодибилдинг"
    },
    {
        "id": "5",
        "title": "Боулинг"
    },
    {
        "id": "6",
        "title": "Боулспорт"
    },
    {
        "id": "7",
        "title": "Выполнение норм ГТО"
    },
    {
        "id": "8",
        "title": "Го"
    },
    {
        "id": "9",
        "title": "Гольф"
    },
    {
        "id": "10",
        "title": "Городошный спорт"
    },
    {
        "id": "11",
        "title": "Гребной слалом"
    },
    {
        "id": "12",
        "title": "Гребной спорт"
    },
    {
        "id": "13",
        "title": "Дартс"
    },
    {
        "id": "14",
        "title": "Компьютерный спорт"
    },
    {
        "id": "15",
        "title": "Лапта"
    },
    {
        "id": "16",
        "title": "Настольный теннис"
    },
    {
        "id": "17",
        "title": "Плавание"
    },
    {
        "id": "18",
        "title": "Планерный спорт"
    },
    {
        "id": "19",
        "title": "Радиоспорт"
    },
    {
        "id": "20",
        "title": "Рыболовный спорт"
    },
    {
        "id": "21",
        "title": "Сквош"
    },
    {
        "id": "22",
        "title": "Спортивное ориентирование"
    },
    {
        "id": "23",
        "title": "Спортивный бридж"
    },
    {
        "id": "24",
        "title": "Судомодельный спорт"
    },
    {
        "id": "25",
        "title": "Шахматы"
    },
    {
        "id": "26",
        "title": "Шашки"
    },
    {
        "id": "27",
        "title": "Эстетическая гимнастика"
    },
    {
        "id": "28",
        "title": "Акробатический рок-н-ролл"
    },
    {
        "id": "29",
        "title": "Армрестлинг"
    },
    {
        "id": "30",
        "title": "Баскетбол"
    },
    {
        "id": "31",
        "title": "Бейсбол"
    },
    {
        "id": "32",
        "title": "Биатлон"
    },
    {
        "id": "33",
        "title": "Велосипедный спорт"
    },
    {
        "id": "34",
        "title": "Водное поло"
    },
    {
        "id": "35",
        "title": "Волейбол"
    },
    {
        "id": "36",
        "title": "Гандбол"
    },
    {
        "id": "37",
        "title": "Гребля на байдарках и каноэ"
    },
    {
        "id": "38",
        "title": "Керлинг"
    },
    {
        "id": "39",
        "title": "Кинологический спорт"
    },
    {
        "id": "40",
        "title": "Конный спорт"
    },
    {
        "id": "41",
        "title": "Конькобежный спорт"
    },
    {
        "id": "42",
        "title": "Корфбол"
    },
    {
        "id": "43",
        "title": "Легкая атлетика"
    },
    {
        "id": "44",
        "title": "Лыжное двоеборье"
    },
    {
        "id": "45",
        "title": "Лыжные гонки"
    },
    {
        "id": "46",
        "title": "Мас-рестлинг"
    },
    {
        "id": "47",
        "title": "Мини-футбол"
    },
    {
        "id": "48",
        "title": "Парусный спорт"
    },
    {
        "id": "49",
        "title": "Перетягивание каната"
    },
    {
        "id": "50",
        "title": "Полиатлон"
    },
    {
        "id": "51",
        "title": "Практическая стрельба"
    },
    {
        "id": "52",
        "title": "Прыжки в воду"
    },
    {
        "id": "53",
        "title": "Пулевая стрельба"
    },
    {
        "id": "54",
        "title": "Пэйнтбол"
    },
    {
        "id": "55",
        "title": "Роллер спорт"
    },
    {
        "id": "56",
        "title": "Серфинг"
    },
    {
        "id": "57",
        "title": "Синхронное плавание"
    },
    {
        "id": "58",
        "title": "Софтбол"
    },
    {
        "id": "59",
        "title": "Спортивное метание ножа"
    },
    {
        "id": "60",
        "title": "Спортивно-прикладное собаководство"
    },
    {
        "id": "61",
        "title": "Спортивный туризм"
    },
    {
        "id": "62",
        "title": "Стендовая стрельба"
    },
    {
        "id": "63",
        "title": "Стрельба из арбалета"
    },
    {
        "id": "64",
        "title": "Стрельба из лука"
    },
    {
        "id": "65",
        "title": "Танцевальный спорт"
    },
    {
        "id": "66",
        "title": "Теннис"
    },
    {
        "id": "67",
        "title": "Триатлон"
    },
    {
        "id": "68",
        "title": "Фехтование"
    },
    {
        "id": "69",
        "title": "Фигурное катание на коньках"
    },
    {
        "id": "70",
        "title": "Фитнес-аэробика"
    },
    {
        "id": "71",
        "title": "Флорбол"
    },
    {
        "id": "72",
        "title": "Футбол"
    },
    {
        "id": "73",
        "title": "Футбол-МФФ"
    },
    {
        "id": "74",
        "title": "Хоккей на траве"
    },
    {
        "id": "75",
        "title": "Хоккей с мячом"
    },
    {
        "id": "76",
        "title": "Художественная гимнастика"
    },
    {
        "id": "77",
        "title": "Чир спорт"
    },
    {
        "id": "78",
        "title": "Айкидо"
    },
    {
        "id": "79",
        "title": "Американский футбол"
    },
    {
        "id": "80",
        "title": "Бобслей"
    },
    {
        "id": "81",
        "title": "Бокс"
    },
    {
        "id": "82",
        "title": "Борьба на поясах"
    },
    {
        "id": "83",
        "title": "Воднолыжный спорт"
    },
    {
        "id": "84",
        "title": "Восточное боевое единоборство"
    },
    {
        "id": "85",
        "title": "Всестилевое каратэ"
    },
    {
        "id": "86",
        "title": "Гиревой спорт"
    },
    {
        "id": "87",
        "title": "Горнолыжный спорт"
    },
    {
        "id": "88",
        "title": "Джиу-джитсу"
    },
    {
        "id": "89",
        "title": "Дзюдо"
    },
    {
        "id": "90",
        "title": "Ездовой спорт"
    },
    {
        "id": "91",
        "title": "Капоэйра"
    },
    {
        "id": "92",
        "title": "Каратэ"
    },
    {
        "id": "93",
        "title": "Кендо"
    },
    {
        "id": "94",
        "title": "Кикбоксинг"
    },
    {
        "id": "95",
        "title": "Киокусинкай"
    },
    {
        "id": "96",
        "title": "Корэш"
    },
    {
        "id": "97",
        "title": "Кудо"
    },
    {
        "id": "98",
        "title": "Прыжки на батуте"
    },
    {
        "id": "99",
        "title": "Рукопашный бой"
    },
    {
        "id": "100",
        "title": "Сават"
    },
    {
        "id": "101",
        "title": "Самбо"
    },
    {
        "id": "102",
        "title": "Санный спорт"
    },
    {
        "id": "103",
        "title": "Северное многоборье"
    },
    {
        "id": "104",
        "title": "Скейтбординг"
    },
    {
        "id": "105",
        "title": "Сноуборд"
    },
    {
        "id": "106",
        "title": "Современное пятиборье"
    },
    {
        "id": "107",
        "title": "Спортивная акробатика"
    },
    {
        "id": "108",
        "title": "Спортивная аэробика"
    },
    {
        "id": "109",
        "title": "Спортивная борьба"
    },
    {
        "id": "110",
        "title": "Спортивная гимнастика"
    },
    {
        "id": "111",
        "title": "Сумо"
    },
    {
        "id": "112",
        "title": "Тайский бокс"
    },
    {
        "id": "113",
        "title": "Тхэквондо"
    },
    {
        "id": "114",
        "title": "Тхэквондо ГТФ"
    },
    {
        "id": "115",
        "title": "Тхэквондо МФТ"
    },
    {
        "id": "116",
        "title": "Тяжелая атлетика"
    },
    {
        "id": "117",
        "title": "Универсальный бой"
    },
    {
        "id": "118",
        "title": "Ушу"
    },
    {
        "id": "119",
        "title": "Функциональное многоборье"
    },
    {
        "id": "120",
        "title": "Хоккей"
    },
    {
        "id": "121",
        "title": "Автомобильный спорт"
    },
    {
        "id": "122",
        "title": "Альпинизм"
    },
    {
        "id": "123",
        "title": "Вертолетный спорт"
    },
    {
        "id": "124",
        "title": "Водно-моторный спорт"
    },
    {
        "id": "125",
        "title": "Воздухоплавательный спорт"
    },
    {
        "id": "126",
        "title": "Морское многоборье"
    },
    {
        "id": "127",
        "title": "Мотоциклетный спорт"
    },
    {
        "id": "128",
        "title": "Парашютный спорт"
    },
    {
        "id": "129",
        "title": "Пауэрлифтинг"
    },
    {
        "id": "130",
        "title": "Подводный спорт"
    },
    {
        "id": "131",
        "title": "Прыжки на лыжах с трамплина"
    },
    {
        "id": "132",
        "title": "Рафтинг"
    },
    {
        "id": "133",
        "title": "Регби"
    },
    {
        "id": "134",
        "title": "Самолетный спорт"
    },
    {
        "id": "135",
        "title": "Скалолазание"
    },
    {
        "id": "136",
        "title": "Смешанные боевые единоборства (ММА)"
    },
    {
        "id": "137",
        "title": "Спорт сверхлегкой авиации"
    },
    {
        "id": "138",
        "title": "Фристайл"
    }
]

export const payPeriods = [
    {
        "val": "1",
        "title": "1 день"
    },
    {
        "val": "2",
        "title": "2 дня"
    },
    {
        "val": "3",
        "title": "3 дня"
    },
    {
        "val": "4",
        "title": "4 дня"
    },
    {
        "val": "5",
        "title": "5 дней"
    },
    {
        "val": "6",
        "title": "6 дней"
    },
    {
        "val": "7",
        "title": "7 дней"
    },
    {
        "val": "8",
        "title": "8 дней"
    },
    {
        "val": "9",
        "title": "9 дней"
    },
    {
        "val": "10",
        "title": "10 дней"
    },
    {
        "val": "11",
        "title": "11 дней"
    },
    {
        "val": "12",
        "title": "12 дней"
    },
    {
        "val": "13",
        "title": "13 дней"
    },
    {
        "val": "14",
        "title": "14 дней"
    },
    {
        "val": "15",
        "title": "15 дней"
    },
    {
        "val": "16",
        "title": "16 дней"
    },
    {
        "val": "17",
        "title": "17 дней"
    },
    {
        "val": "18",
        "title": "18 дней"
    },
    {
        "val": "19",
        "title": "19 дней"
    },
    {
        "val": "20",
        "title": "20 дней"
    },
    {
        "val": "21",
        "title": "21 день"
    },
    {
        "val": "22",
        "title": "22 дня"
    },
    {
        "val": "23",
        "title": "23 дня"
    },
    {
        "val": "24",
        "title": "24 дня"
    },
    {
        "val": "25",
        "title": "25 дней"
    },
    {
        "val": "26",
        "title": "26 дней"
    },
    {
        "val": "27",
        "title": "27 дней"
    },
    {
        "val": "28",
        "title": "28 дней"
    },
    {
        "val": "29",
        "title": "29 дней"
    },
    {
        "val": "30",
        "title": "30 дней"
    },
    {
        "val": "31",
        "title": "31 день"
    },
    {
        "val": "90",
        "title": "3 месяца"
    },
    {
        "val": "180",
        "title": "6 месяцев"
    },
    {
        "val": "365",
        "title": "12  месяцев"
    }
]

export const payLimits = [
    {
        "val": "50000",
        "title": "50 000"
    },
    {
        "val": "100000",
        "title": "100 000"
    },
    {
        "val": "150000",
        "title": "150 000"
    },
    {
        "val": "200000",
        "title": "200 000"
    },
    {
        "val": "300000",
        "title": "300 000"
    },
    {
        "val": "400000",
        "title": "400 000"
    },
    {
        "val": "500000",
        "title": "500 000"
    },
    {
        "val": "600000",
        "title": "600 000"
    },
    {
        "val": "700000",
        "title": "700 000"
    }
]