import { ChangeEvent, useEffect, useState } from "react"
import { Link, useParams, useSearchParams } from "react-router-dom"


export const DonationPage = () => {
    return(
        <div className="row">
            <div className="col-12">
                <h1 className="page-title">Членские взносы</h1>
            </div>
            <div className="col-md-9">
                <div className="lk-block search">
                    <input type="text" placeholder="Поиск..." />
                    <button>
                        <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.7955 15.8111L21 21M18 10.5C18 14.6421 14.6421 18 10.5 18C6.35786 18 3 14.6421 3 10.5C3 6.35786 6.35786 3 10.5 3C14.6421 3 18 6.35786 18 10.5Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </button>
                </div>
            </div>
            <div className="col-md-3">
                <Link to={'/donation/add/'} className="add-btn">
                    Добавить элемент
                </Link>
            </div>
            <div className="col-md-12">
                <div className="lk-block">
                    <div className="table-wrap">
                        <table>
                            <thead>
                                <tr>
                                    <th>id</th>
                                    <th>Статус</th>
                                    <th>Время операции</th>
                                    <th>Дата завершения</th>
                                    <th>Пользователь</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td data-label="id">
                                        <Link to={`/donation/${1}/`}>112</Link>
                                    </td>
                                    <td data-label="Статус">Выполнено</td>
                                    <td data-label="Время операции">12.12.2003 12:12</td>
                                    <td data-label="Дата завершения">12.12.2003 12:12</td>
                                    <td data-label="Пользователь">
                                        [<Link to={'/'}>123</Link>] test@gmail.com
                                    </td>
                                </tr>
                                <tr>
                                    <td data-label="id">
                                        <Link to={`/donation/${1}/`}>112</Link>
                                    </td>
                                    <td data-label="Статус">Выполнено</td>
                                    <td data-label="Время операции">12.12.2003 12:12</td>
                                    <td data-label="Дата завершения">12.12.2003 12:12</td>
                                    <td data-label="Пользователь">
                                        [<Link to={'/'}>123</Link>] test@gmail.com
                                    </td>
                                </tr>
                                <tr>
                                    <td data-label="id">
                                        <Link to={`/donation/${1}/`}>112</Link>
                                    </td>
                                    <td data-label="Статус">Выполнено</td>
                                    <td data-label="Время операции">12.12.2003 12:12</td>
                                    <td data-label="Дата завершения">12.12.2003 12:12</td>
                                    <td data-label="Пользователь">
                                        [<Link to={'/'}>123</Link>] test@gmail.com
                                    </td>
                                </tr>
                                <tr>
                                    <td data-label="id">
                                        <Link to={`/donation/${1}/`}>112</Link>
                                    </td>
                                    <td data-label="Статус">Выполнено</td>
                                    <td data-label="Время операции">12.12.2003 12:12</td>
                                    <td data-label="Дата завершения">12.12.2003 12:12</td>
                                    <td data-label="Пользователь">
                                        [<Link to={'/'}>123</Link>] test@gmail.com
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}