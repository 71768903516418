import { Outlet, useLocation, useParams } from "react-router-dom";
import { Header } from "../component/Header";
import { LeftMenu } from "../component/LeftMenu";
import { Footer } from "../component/Footer";
import { FrontHeader } from "../component/FrontHeader";
import { FrontFooter } from "../component/FrontFooter";

export const Layout = () => {
    
    let location = useLocation();
    const frontList = [
        '/',
        '/login/',
        '/developers/'
    ]
    return (
        <>
            {
                frontList.indexOf(location.pathname) >= 0 ? (
                    <div>
                        {
                            location.pathname != "/login/" ? (
                                <>
                                    <FrontHeader />
                                    <Outlet />
                                    <FrontFooter /> 
                                </>
                            ):(
                                <Outlet />
                            )
                        }
                    </div>
                ):(
                    <div className="hide-h">
                        <Header />
                        <div className="content"> 
                            <LeftMenu />
                            <div className="mainspace"> 
                                <div className="mainspace__wrapper">
                                    <Outlet />
                                </div>
                                <Footer />
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    );
};
